import {createApp} from 'vue'
import App from './App.vue'
import router from './router'

import 'uikit/dist/css/uikit.min.css'
import 'uikit/dist/css/uikit-core.min.css'
import 'uikit/dist/js/uikit.min'
import 'uikit/dist/js/uikit-core.min'
import 'uikit/dist/js/uikit-icons.min'
import auth from "./auth/auth";
// @ts-ignore
import ApiClient from "@/services/client/ApiClient";

ApiClient.setupDefaults()

createApp(App).use(router).use(auth).mount('#app')
