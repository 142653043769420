import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home                                               from '../views/Home.vue'
import NotFound                                         from '../views/NotFound.vue'
import Forbidden                                        from '../views/Forbidden.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta     : {auth: true}
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import((`@/components/auth/LoginComponent.vue`)),
    meta     : {auth: false}
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: () => import('@/components/auth/LogoutComponent.vue'),
    meta     : {auth: true}
  },
  {
    path: '/employee/personal',
    name: 'employee/personal',
    component: () => import((`@/components/form/Personal.vue`)),
    meta     : {auth: true}
  },
  {
    path: '/employee/passport',
    name: 'employee/passport',
    component: () => import((`@/components/form/Passport.vue`)),
    meta     : {auth: true}
  },
  {
    path: '/employee/address',
    name: 'employee/address',
    component: () => import((`@/components/form/Address.vue`)),
    meta     : {auth: true}
  },
  {
    path: '/employee/bank',
    name: 'employee/bank',
    component: () => import((`@/components/form/Bank.vue`)),
    meta     : {auth: true}
  },
  {
    path: '/employee/confirmation',
    name: 'employee/confirmation',
    component: () => import((`@/components/form/Confirmation.vue`)),
    meta     : {auth: true}
  },
  {
    path     : "/403",
    component: Forbidden
  },
  {
    path     : "/:catchAll(.*)",
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
