
import {defineComponent} from 'vue';
import {useRouter}       from "vue-router";

export default defineComponent({
  name      : 'Home',
  components: {
  },
  setup() {
    let router = useRouter();

    router.push({name: 'employee/personal'})
  }
});
