<template>
  <Header/>
  <div class="uk-container">
    <router-view v-slot="{ Component }">
      <component :is="Component"/>
    </router-view>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Header            from '@/components/Header.vue'; // @ is an alias to /src

export default defineComponent({
  components: {
    Header,
  },
});
</script>

<style lang="scss">

</style>
